import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WorkSlotService {
  constructor(private http: HttpClient) {}

  public updateWorkSlot(data, id) {
    return this.http.patch<any>(
      environment.apiUrl + "work-slot/" + id + "/",
      data
    );
  }

  public getWorkSlotById(id): Observable<any> {
    return this.http.get<any>(
      environment.apiUrl + "work-slot/?schedule_id=" + id
    );
  }

  public getWorkSlots(schedule) {
    return this.http.get<any>(environment.apiUrl + "work-slot/", schedule);
  }

  public sendWorkSlot(body) {
    return this.http.post<any>(environment.apiUrl + "work-slots/", body);
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { DialogComponent } from "../dialog/dialog.component";
import { edit } from "../grid/grid.component";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { minutesToSeconds, secondsToHours } from "date-fns";
import { hoursToSeconds } from "date-fns/esm";
import { OccupationFieldService } from "src/app/_services/occupation-fied.service";
export interface DialogData {
  description: string;
  enableCancel: boolean;
  selectUnity: any;
  grid: any;
}

@Component({
  selector: "medlog-criargrade",
  templateUrl: "./create-grid.component.html",
  styleUrls: ["./create-grid.component.scss"],
})
export class CreateGridComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateGridComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private occupationFieldService: OccupationFieldService,
    public dialog: MatDialog
  ) {}
  healthUnits;
  selectedUnit;
  healthProfessionals;
  selectedProfessional;
  loading = true;
  pt: any;
  dateTime;
  modelDataInicio = 0;
  modelDataFim = 0;
  datainicial;
  dataFinal;
  datainicialFormatada;
  datafinalFormatada;
  editModel: any;
  editType = edit;
  hourEnd;

  //grade
  ocupation;
  selectedCategory;
  isSunday = false;
  isMonday = false;
  isTuesday = false;
  isWednesday = false;
  isThursday = false;
  isFriday = false;
  isSaturday = false;
  nomeGrade;
  expertise;
  selectedExpertise = [];
  dataWorkedSheet = [];
  totalGrad = 0;
  isEdit = false;

  onNoClick(): void {
    this.dialogRef.close("0");
  }
  addRow() {
    this.dataWorkedSheet.push({
      name: "",
      duration: "",
      start_time: "",
      horaFim: "",
      professionals_count: "",
    });
    this.totalGrad = this.dataWorkedSheet.length - 1;
    window.scroll(0, 40);
  }

  calculateHour(date) {
    if (date.start_time != "" && date.duration != "") {
      var today = new Date();
      console.log(date.start_time);
      var start = date.start_time.split(":");
      var duration = date.duration.split(":");
      let startHours = parseInt(start[0]);
      let startMinutes = parseInt(start[1]);
      let durationHours = parseInt(duration[0]);
      let durationMinutes = parseInt(duration[1]);
      startHours = hoursToSeconds(startHours);
      startMinutes = minutesToSeconds(startMinutes);
      durationHours = hoursToSeconds(durationHours);
      durationMinutes = minutesToSeconds(durationMinutes);

      const allSeconds =
        startHours + startMinutes + durationHours + durationMinutes;

      const endHours = this.convertHMS(allSeconds);
      date.horaFim = endHours;
      return date;
    }
  }
  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds: any = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02

    if (hours > 24) {
      hours = hours - 24;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes > 60) {
      minutes = minutes - 60;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    return hours + ":" + minutes + ":" + "00"; // Return is HH : MM : SS
  }

  deleteRow(position) {
    var newSheet = this.dataWorkedSheet;
    console.log(newSheet);
    this.dataWorkedSheet = [];
    var cc = 0;
    for (var i = 0; i < newSheet.length; i++) {
      if (i != position) {
        this.dataWorkedSheet.push({
          nomeTurno: newSheet[i].nomeTurno,
          jornada: newSheet[i].jornada,
          horaInicio: newSheet[i].horaInicio,
          horaFim: newSheet[i].horaFim,
          profissionais: newSheet[i].profissionais,
        });
        cc++;
      }
      console.log(this.dataWorkedSheet);
      if (i == newSheet.length - 1) {
        this.totalGrad = this.dataWorkedSheet.length - 1;
      }
    }

    //this.totalGrad = this.dataWorkedSheet.length-1;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async ngOnInit() {
    await this.getUnits();

    // if(this.data.selectUnity){
    //   console.log(this.data.selectUnity, 'select')
    //   console.log(this.healthUnits?.results, 'results')
    //   this.selectedUnit = this.healthUnits?.results.find((unit)=> unit.id === this.data.selectUnity.id)
    // }
    if (this.data.grid) {
      console.log(this.data.grid);
      // this.selectedUnit = this.healthUnits?.results.find((unit)=> unit.id === this.data.grid.health_unit)
      this.isEdit = true;

      this.dataWorkedSheet = this.data.grid.planned_workshifts || [];
      for (let date of this.dataWorkedSheet) {
        date = this.calculateHour(date);
      }
      this.isSunday = this.data.grid.days_of_week.sunday;
      this.isMonday = this.data.grid.days_of_week.monday;
      this.isTuesday = this.data.grid.days_of_week.tuesday;
      this.isWednesday = this.data.grid.days_of_week.wednesday;
      this.isThursday = this.data.grid.days_of_week.thursday;
      this.isFriday = this.data.grid.days_of_week.friday;
      this.isSaturday = this.data.grid.days_of_week.saturday;
      this.nomeGrade = this.data.grid.name;
    } else {
      this.dataWorkedSheet.push({
        name: "",
        duration: "",
        start_time: "",
        horaFim: "",
        professionals_count: "",
      });
    }
    if (this.data.selectUnity) {
      this.selectedUnit = this.healthUnits?.results.find(
        (unit) => unit.id === this.data.selectUnity.id
      );
    }
  }

  getOcupation() {
    this.occupationFieldService.getOccupation().subscribe((data) => {
      this.loading = false;
      console.log(data);
      this.ocupation = data;

      this.ocupation.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      // if (this.data.grid) {
      //   this.ocupation.results.filter((result) => {
      //     result.id != this.data.grid.occupation;
      //   });

      //   console.log(this.ocupation.results);
      // } else {
      this.ocupation.results.unshift({
        name: "Selecione a Categoria",
        id: 0,
      });
      // }

      if (this.data.grid) {
        this.selectedCategory = this.ocupation.results.find(
          (data) => data.id === this.data.grid.occupation_area
        );
        console.log(this.selectedCategory, "selectedCategory");
        this.expertise = this.selectedCategory?.areas_of_expertise || [];
        console.log(this.data.grid.areas_of_expertise);
        console.log(this.expertise, "this.expertise");
        if (this.data.grid.areas_of_expertise) {
          const areas = [];
          for (const id of this.data.grid.areas_of_expertise) {
            const filtered = this.expertise.filter(
              (expertise) => expertise.id === id
            );
            if (filtered) {
              areas.push(filtered);
            }
          }
          if (areas.length) {
            this.selectedExpertise = areas;
          }
          console.log(areas, "areas");
        }

        console.log();

        this.ocupation.results.unshift({
          name: this.data.grid.occupation_area_name,
          id: this.data.grid.occupation,
        });

        //let selectedRole = this.ocupatiodatan.results.find(x => x.id == this..selectUnity["health_unit"]);
      }

      //this.healthUnits.results.reverse();
    });
  }

  getUnits() {
    const params = {
      health_unit_status: "enable",
    };
    this.healthUnitService.getHealthUnits(params).subscribe((data) => {
      console.log(data, "unitssss");
      this.getOcupation();
      this.healthUnits = data;

      // this.healthUnits.results.sort(function (a, b) {
      //   var nameA = a.name.toLowerCase(),
      //     nameB = b.name.toLowerCase();
      //   if (nameA < nameB)
      //     //sort string ascending
      //     return -1;
      //   if (nameA > nameB) return 1;
      //   return 0; //default return value (no sorting)
      // });

      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: 0 });

      if (this.data.selectUnity || this.data.grid.health_unit) {
        this.selectedUnit = this.healthUnits.results.find(
          (unit) =>
            unit.id === this.data?.selectUnity?.id ||
            unit.id === this.data?.grid?.health_unit
        );
      }

      this.getOcupation();

      //this.healthUnits.results.reverse();
    });
  }

  selectDay(event) {
    var diaSelecionado = event.currentTarget.id;
    if (diaSelecionado == "domingo") {
      if (this.isSunday) {
        this.isSunday = false;
      } else {
        this.isSunday = true;
      }
    } else if (diaSelecionado == "segunda") {
      if (this.isMonday) {
        this.isMonday = false;
      } else {
        this.isMonday = true;
      }
    } else if (diaSelecionado == "terca") {
      if (this.isTuesday) {
        this.isTuesday = false;
      } else {
        this.isTuesday = true;
      }
    } else if (diaSelecionado == "quarta") {
      if (this.isWednesday) {
        this.isWednesday = false;
      } else {
        this.isWednesday = true;
      }
    } else if (diaSelecionado == "quinta") {
      if (this.isThursday) {
        this.isThursday = false;
      } else {
        this.isThursday = true;
      }
    } else if (diaSelecionado == "sexta") {
      if (this.isFriday) {
        this.isFriday = false;
      } else {
        this.isFriday = true;
      }
    } else {
      if (this.isSaturday) {
        this.isSaturday = false;
      } else {
        this.isSaturday = true;
      }
    }
  }

  ngOnDestroy() {
    this.editModel = this.data.description;
  }

  createRegister() {
    if (
      this.selectedUnit &&
      this.modelDataInicio != 0 &&
      this.modelDataFim != 0
    ) {
      this.datainicial =
        new Date(this.modelDataInicio + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";
      this.dataFinal =
        new Date(this.modelDataFim + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";

      var parametros = new IHealthUnit();

      parametros.health_unit = this.selectedUnit.id;
      parametros.health_professional =
        this.selectedProfessional.health_professional_id;
      parametros.adjusted_start_datetime = this.datainicial;
      parametros.adjusted_end_datetime = this.dataFinal;
      parametros.disable_adjustment = "false";
      parametros.image = this.selectedProfessional.profile.photo;
      parametros.doctor = this.selectedProfessional.name;
      parametros.clinic = this.selectedUnit.name;
      parametros.modelDataInicio = this.modelDataInicio;
      parametros.modelDataFim = this.modelDataFim;

      this.dialogRef.close(parametros);
    } else {
      this.loading = false;
      this.openDialog("Necessário preencher os campos.");
    }
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  criarGrade() {
    if (
      !this.healthUnits.results ||
      !this.nomeGrade ||
      !this.selectedCategory
    ) {
      this.openDialog("Informe todos os dados necessarios!");
    } else {
      const dataPeriod = [];
      this.dataWorkedSheet.map((data) => {
        data.horaFim =
          data.horaFim[0] +
          data.horaFim[1] +
          ":" +
          data.horaFim[2] +
          data.horaFim[3];
        data.duration = data.duration + ":00";
        data.start_time = data.start_time + ":00";
        dataPeriod.push({
          duration: data.duration,
          horaFim: data.horaFim,
          name: data.name,
          professionals_count: data.professionals_count,
          start_time: data.start_time,
        });
      });
      let dataNamePeriod = [];
      dataPeriod.map((data) => {
        dataNamePeriod.push(data.name);
      });

      var duplicado = this.hasDuplicates(dataNamePeriod);
      console.log(duplicado);
      if (!duplicado) {
        let data = {
          name: this.nomeGrade,
          health_unit: this.selectedUnit.id,
          occupation_area: this.selectedCategory.id,
          days_of_week: {
            sunday: this.isSunday,
            monday: this.isMonday,
            tuesday: this.isTuesday,
            wednesday: this.isWednesday,
            thursday: this.isThursday,
            friday: this.isFriday,
            saturday: this.isSaturday,
          },
          planned_workshifts: dataPeriod,
          areas_of_expertise: null,
        };
        console.log(data);

        if (this.selectedExpertise.length)
          data.areas_of_expertise = this.selectedExpertise.map(
            (value) => value.id
          );
        this.dialogRef.close(data);
      } else {
        this.openDialog("Os turnos não podem ter o mesmo nome!");
      }
    }
  }
  hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  editarGrade() {
    if (
      !this.healthUnits.results ||
      !this.nomeGrade ||
      !this.selectedCategory
    ) {
      this.openDialog("Informe todos os dados necessarios!");
    } else {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "Aviso!",
          message:
            "As alterações realizadas nesta grade não serão aplicadas para as escalas em andamento. A nova configuração estará disponível para as novas escalas que forem cadastradas. ",
        },
        width: "750px",
        height: "200px",
      });

      confirmDialog.afterClosed().subscribe((result) => {
        if (result === true) {
          console.log(this.dataWorkedSheet);

          const dataPeriod = [];
          this.dataWorkedSheet.map((data) => {
            data.horaFim =
              data.horaFim[0] +
              data.horaFim[1] +
              ":" +
              data.horaFim[2] +
              data.horaFim[3];
            data.duration = data.duration + ":00";
            data.start_time = data.start_time + ":00";
            dataPeriod.push({
              duration: data.duration,
              horaFim: data.horaFim,
              name: data.name,
              professionals_count: data.professionals_count,
              start_time: data.start_time,
            });
          });
          let dataNamePeriod = [];
          dataPeriod.map((data) => {
            dataNamePeriod.push(data.name);
          });
          var duplicado = this.hasDuplicates(dataNamePeriod);
          console.log(duplicado);
          if (!duplicado) {
            let data = {
              name: this.nomeGrade,
              health_unit: this.selectedUnit.id,
              occupation_area: this.selectedCategory.id,
              days_of_week: {
                sunday: this.isSunday,
                monday: this.isMonday,
                tuesday: this.isTuesday,
                wednesday: this.isWednesday,
                thursday: this.isThursday,
                friday: this.isFriday,
                saturday: this.isSaturday,
              },

              planned_workshifts: dataPeriod,
              areas_of_expertise: null,
            };
            console.log(data);
            if (this.selectedExpertise.length)
              data.areas_of_expertise = this.selectedExpertise.map(
                (value) => value.id
              );
            this.dialogRef.close(data);
          } else {
            this.openDialog("Os turnos não podem ter o mesmo nome!");
          }
        }
      });
    }
  }
}

import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { iUnit } from "../_models/unit.model";

@Injectable({
  providedIn: "root",
})
export class OccupationFieldService {
  constructor(private http: HttpClient) {}

  public getOccupation(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + "occupation-field/");
  }

  public getOccupationById(id: string): Observable<any> {
    return this.http.get<any[]>(
      environment.apiUrl + "occupation-field/" + id + "/"
    );
  }

  public getOcupation(): Observable<iUnit> {
    return this.http.get<any>(environment.apiUrl + "occupation-field/");
  }

  public getOcupationById(id: string): Observable<iUnit> {
    return this.http.get<any>(
      environment.apiUrl + "occupation-field/" + id + "/"
    );
  }
}

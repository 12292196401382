import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { from, Observable } from "rxjs";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { formatDate } from "@angular/common";
import { UnitsModel } from "src/app/_models/units.model";
import { Router } from "@angular/router";
import {
  OpenStreetMapProvider,
  GeoSearchControl,
  EsriProvider,
} from "leaflet-geosearch";
import * as L from "leaflet";
import * as H from "esri-leaflet-geocoder";
import { RadioButtonModule } from "primeng/radiobutton";
import { AddressModel } from "src/app/_models/address.model";
import { iUnit } from "src/app/_models/unit.model";
import { DialogComponent } from "../dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { FindModel } from "src/app/_models/find.model";
import { cadModel } from "src/app/_models/cad.model";
import { profileModel } from "src/app/_models/profile.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OccupationFieldService } from "src/app/_services/occupation-fied.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { LocationService } from "src/app/_services/location.service";
import { UserInfoService } from "src/app/_services/user-info.service";
import { validatorEmail } from "src/utils";

const ELEMENT_DATA: IClinicReportModel[] = [];

var componentNew;

const provider = new EsriProvider();

interface IUploadProgress {
  filename: string;
  progress: number;
}

declare var AzureStorage: any;

@Component({
  selector: "medlog-cadprofessional",
  templateUrl: "./cadprofessional.component.html",
  styleUrls: ["./cadprofessional.component.scss"],
})
export class CadProfessionalComponent implements OnInit {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private healthUnitService: HealthUnitService,
    private occupationFieldService: OccupationFieldService,
    private authService: AuthenticationService,
    private locationService: LocationService,
    private userInfoService: UserInfoService
  ) {}

  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  initial: any;
  showTable: boolean = false;
  cols: any[];
  map;
  procura;
  teste;
  type;
  state;
  addressObj = [];
  points = L.layerGroup();
  address: AddressModel;
  loading = false;

  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  public unitFilter: IHealthUnit;
  unit: iUnit;
  bairro;
  cep;
  email;
  clinics: UnitsModel[];
  pt: any;
  healthType;
  stateCombo = [];
  genreCombo;
  stateComboAdd = [];
  ocupationCombo;
  especialidadeCombo;
  options;
  title;
  genre;
  cadModel: cadModel;
  profileModel: profileModel;
  ocupation;
  ufConselho;
  arrayEspecialidade;
  especialidade;
  dateNiver;
  blobService;
  uploadProgress$: Observable<IUploadProgress[]>;
  filesSelected = false;
  picProfile;
  picCrm;
  flagProfile = false;
  flagPic = false;
  picProfileName;
  picCrmName;
  confemail;
  lblFoto;
  lblCRM;

  async ngOnInit() {
    this.lblFoto = "Foto perfil";
    this.lblCRM = "Foto documento conselho";

    this.picProfile = "";
    this.picCrm = "";
    this.blobService = AzureStorage.Blob.createBlobService(
      "DefaultEndpointsProtocol=https;AccountName=mediclog;AccountKey=6GYe4m9oPynRROntIhDoGfdnur4HhQlYg8+8oDzRDlyzd13KLmbRzmSw2q67QAtQBX3x+WO8CYS9ti8MXHca4g==;EndpointSuffix=core.windows.net"
    );
    this.blobService.listContainersSegmented(null, function (error, results) {
      if (error) {
        // List container error
      } else {
        for (var i = 0, container; (container = results.entries[i]); i++) {}
      }
    });

    this.title = localStorage.getItem("titulo");
    this.loading = true;
    this.address = new AddressModel();
    this.unit = new iUnit();
    this.unit.radius = 50;

    this.cadModel = new cadModel();
    this.profileModel = new profileModel();
    this.cadModel.profile = this.profileModel;

    await this.getState();
    this.especialidadeCombo = [];
    this.especialidadeCombo.push({ label: "Especialidade", value: null });

    this.genreCombo = [];
    this.genreCombo.push({ label: "Estado Civil", value: null });
    this.genreCombo.push({ label: "Solteiro (a)", value: "single" });
    this.genreCombo.push({ label: "Casado (a)", value: "married" });
    this.genreCombo.push({ label: "Divorciado (a)", value: "divorced" });
    this.genreCombo.push({ label: "Viúvo (a)", value: "widow" });

    this.healthUnitService.getType().subscribe(
      (data) => {
        this.loading = false;
        this.healthType = data;
        this.type = this.healthType.results[0].id;
      },
      (error) => {
        this.loading = false;
        console.error(error);
        return error;
      }
    );

    this.occupationFieldService.getOcupation().subscribe(
      (data) => {
        this.loading = false;
        this.arrayEspecialidade = data["results"];
        this.ocupationCombo = [];
        this.ocupationCombo.push({ label: "Selecione categoria", value: null });

        for (var i = 0; i < data["count"]; i++) {
          this.ocupationCombo.push({
            label: data["results"][i]["name"],
            value: data["results"][i]["id"],
          });
        }
      },
      (error) => {
        this.loading = false;
        console.error(error);
        return error;
      }
    );
  }
  async getState() {
    await this.locationService.getUfLocation().subscribe((result) => {
      var stateCombo = [];

      var stateComboAdd = [];
      for (const state of result) {
        stateCombo.push({ label: state.sigla, value: state.sigla });

        stateComboAdd.push({ label: state.sigla, value: state.sigla });
      }
      this.stateCombo = stateCombo;
      this.stateComboAdd = stateComboAdd;

      this.stateCombo.sort(function (a, b) {
        var nameA = a.value,
          nameB = b.value;
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.stateCombo.unshift({ label: "UF conselho", value: null });

      this.stateComboAdd.sort(function (a, b) {
        var nameA = a.value,
          nameB = b.value;
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });

      this.stateComboAdd.unshift({ label: "Estado", value: null });
    });
  }
  async checkCpf(cpf: string, email?: string) {
    var emailValid = email ? validatorEmail(email) : null;
    console.log(emailValid);
    if (!email ? true : emailValid) {
      if (cpf != null && cpf.length == 11) {
        if (!email) this.loading = true;

        try {
          this.userInfoService
            .checkExistUser(cpf, emailValid ? email : null)
            .subscribe(
              (result) => {
                this.loading = false;
              },
              (error) => {
                this.loading = false;
                this.openDialogError(
                  email ? "Email já cadastrado!!" : "CPF já cadastrado!!"
                );
              }
            );
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  getLocation(cep: string) {
    if (cep.length == 8) {
      this.loading = true;

      this.locationService.getCepLocation(cep).subscribe((location) => {
        console.log(location);
        if (location.uf) {
          this.state = this.stateComboAdd.find(
            (state) => state.value === location.uf.toLocaleUpperCase()
          );
        }
        if (location.localidade) {
          this.cadModel.profile.city = location.localidade;
        }
        if (location.logradouro) {
          this.cadModel.profile.address = location.logradouro;
        }
        // if (location.complemento) {
        //   this.cadModel.profile.address_complement = location.complemento;
        // }

        this.loading = false;
      });
    }
  }
  markerPin() {
    provider
      .search({ query: this.cadModel.profile.zip_code })
      .then((results) => {
        console.log(results, "meusresultados");
        if (results.length) {
          this.textForm(
            parseFloat(results[0].y).toFixed(6),
            parseFloat(results[0].x).toFixed(6)
          );
        } else {
          this.openDialogError("CEP inválido.");
          this.cadModel.profile.zip_code = null;
        }
      });
  }
  textForm(lat, long) {
    var componentScreen = this;
    componentScreen.loading = true;
    H.reverseGeocode()
      .latlng([lat, long])
      .run(function (error, result) {
        componentScreen.loading = false;
        var str = result.address.Match_addr;
        var res = str.split(",");

        componentScreen.cadModel.profile.address = res[0];
        //componentScreen.bairro = res[1];
        componentScreen.cadModel.profile.city = res[2];
      });
  }

  getEspecialidade(ocupacao) {
    const result = this.arrayEspecialidade.filter((s) =>
      s.id.includes(ocupacao["value"])
    );

    this.especialidadeCombo = [];
    this.especialidadeCombo.push({ label: "ESPECIALIDADE", value: null });

    for (var i = 0; i < result[0]["areas_of_expertise"].length; i++) {
      this.especialidadeCombo.push({
        label: result[0]["areas_of_expertise"][i]["name"],
        value: result[0]["areas_of_expertise"][i]["id"],
      });
    }
  }

  uploadImage(file, nome) {
    var customBlockSize =
      file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    this.blobService.singleBlobPutThresholdInBytes = customBlockSize;

    this.blobService.createBlockBlobFromBrowserFile(
      "images",
      nome,
      file,
      { blockSize: customBlockSize },
      function (error, result, response) {
        if (error) {
          this.openDialogError(
            "Não foi possível fazer o upload, tente novamente"
          );
          return false;
        } else {
          return true;
        }
      }.bind(this)
    );
  }

  onFileInputFoto(event) {
    if (event.target.name == "fotoPerfil") {
      this.picProfile = event.srcElement.files;
      this.lblFoto = event.srcElement.files[0].name;
      this.picProfileName = this.uuid4() + ".jpg";
    } else {
      this.picCrm = event.srcElement.files;
      this.lblCRM = event.srcElement.files[0].name;
      this.picCrmName = this.uuid4() + ".jpg";
    }
  }

  uuid4() {
    function hex(s, b) {
      return (
        s +
        (b >>> 4).toString(16) + // high nibble
        (b & 0b1111).toString(16)
      ); // low nibble
    }

    let r = crypto.getRandomValues(new Uint8Array(16));

    r[6] = (r[6] >>> 4) | 0b01000000; // Set type 4: 0100
    r[8] = (r[8] >>> 3) | 0b10000000; // Set variant: 100

    return (
      r.slice(0, 4).reduce(hex, "") +
      r.slice(4, 6).reduce(hex, "-") +
      r.slice(6, 8).reduce(hex, "-") +
      r.slice(8, 10).reduce(hex, "-") +
      r.slice(10, 16).reduce(hex, "-")
    );
  }

  _dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
      type: mime,
    });
  }

  onFileInputConselho(event) {
    var files = event.srcElement.files[0];
    console.log(files);
  }

  selectRadio(valor) {}

  openDialogSucces(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home/units"]);
    });
  }

  openDialogError(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  backProfessional() {
    this.router.navigate(["home/professional"]);
  }

  saveUnit() {
    this.loading = true;

    if (this.picProfile == "" || this.picCrm == "") {
      this.openDialogError("Necessário preencher campo de foto e CRM!!");
      this.loading = false;
    } else if (this.cadModel.email != this.confemail) {
      this.openDialogError("Campo e-mail e confirmar e-mail estão diferentes!");
      this.loading = false;
    } else if (this.cadModel.password1 != this.cadModel.password2) {
      this.openDialogError("Campo senha e confirmar senha estão diferentes!");
      this.loading = false;
    } else {
      var aPromises = [];

      return new Promise(
        function (resolve, reject) {
          for (var i = 0; i < 2; i++) {
            aPromises.push(
              new Promise(
                function (resolveMaterial, reject) {
                  if (i == 0) {
                    var file = this.picProfile;
                    var customBlockSize =
                      file.size > 1024 * 1024 * 32
                        ? 1024 * 1024 * 4
                        : 1024 * 512;
                    this.blobService.singleBlobPutThresholdInBytes =
                      customBlockSize;

                    this.blobService.createBlockBlobFromBrowserFile(
                      "images",
                      this.picProfileName,
                      file[0],
                      { blockSize: customBlockSize },
                      function (error, result, response) {
                        if (error) {
                          this.openDialogError(
                            "Não foi possível fazer o upload, tente novamente"
                          );
                          resolveMaterial();
                        } else {
                          resolveMaterial();
                        }
                      }.bind(this)
                    );
                  } else {
                    var files = this.picCrm;
                    var customBlockSize =
                      files.size > 1024 * 1024 * 32
                        ? 1024 * 1024 * 4
                        : 1024 * 512;
                    this.blobService.singleBlobPutThresholdInBytes =
                      customBlockSize;

                    this.blobService.createBlockBlobFromBrowserFile(
                      "images",
                      this.picCrmName,
                      files[0],
                      { blockSize: customBlockSize },
                      function (error, result, response) {
                        if (error) {
                          this.openDialogError(
                            "Não foi possível fazer o upload, tente novamente"
                          );
                          resolveMaterial();
                        } else {
                          resolveMaterial();
                        }
                      }.bind(this)
                    );
                  }
                }.bind(this)
              )
            );
          }

          Promise.all(aPromises).then(
            async function () {
              var dataAniversario = this.dateNiver;
              this.cadModel.profile.date_of_birth =
                dataAniversario.substring(4, 8) +
                "-" +
                dataAniversario.substring(2, 4) +
                "-" +
                dataAniversario.substring(0, 2);
              this.cadModel.profile.occupation_area = this.ocupation["value"];
              this.cadModel.username = this.cadModel.email;
              this.cadModel.profile.regional_council_state =
                this.ufConselho["value"];
              this.cadModel.profile.state = this.state["value"];
              this.cadModel.profile.marital_status = this.genre;
              this.cadModel.profile.regional_council_document_picture =
                "https://mediclog.blob.core.windows.net/images/" +
                this.picCrmName;
              this.cadModel.profile.photo =
                "https://mediclog.blob.core.windows.net/images/" +
                this.picProfileName;
              console.log();
              await this.authService.register(this.cadModel).subscribe(
                (data) => {
                  this.cadModel.name = "";
                  this.cadModel.profile.cel_phone = "";
                  this.cadModel.profile.cpf = "";
                  this.cadModel.profile.rg = "";
                  this.cadModel.profile.regional_council_document_number = "";
                  this.cadModel.email = "";
                  this.confemail = "";
                  this.cadModel.password1 = "";
                  this.cadModel.password2 = "";
                  this.cadModel.profile.zip_code = "";
                  this.cadModel.profile.address = "";
                  this.cadModel.profile.address_number = "";
                  this.cadModel.profile.address_complement = "";
                  this.cadModel.profile.city = "";
                  this.cadModel.profile.date_of_birth = "";
                  this.cadModel.profile.occupation_area = "";
                  this.cadModel.username = this.cadModel.email;
                  this.cadModel.profile.regional_council_state = "";
                  this.cadModel.profile.state = "";
                  this.cadModel.profile.marital_status = this.genre;
                  this.cadModel.profile.regional_council_document_picture = "";
                  this.cadModel.profile.photo = "";
                  this.picProfile = "";
                  this.lblFoto = "";
                  this.picProfileName = "";
                  this.picCrm = "";
                  this.lblCRM = "";
                  this.picCrmName = "";
                  this.dateNiver = "";
                  this.lblFoto = "FOTO PERFIL";
                  this.lblCRM = "FOTO DOCUMENTO CONSELHO";
                  this.openDialogSucces("Cadastro concluído com sucesso!!");
                  this.loading = false;
                },
                (error) => {
                  this.loading = false;
                  console.error(error);
                  return error;
                }
              );

              resolve();
            }.bind(this)
          );
        }.bind(this)
      );
    }
  }
}

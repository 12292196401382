import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { SelectItem } from "primeng/components/common/selectitem";
import { DialogComponent } from "src/app/home/dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { FormBuilder, FormControl } from "@angular/forms";

import { Company } from "src/app/_models/company.model";
import { isThisISOWeek } from "date-fns";
import { CompanyService } from "src/app/_services/company.service";
import { createTrue } from "typescript";

declare var AzureStorage: any;

@Component({
  selector: "medlog-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  open = false;
  title: string;
  cities1: SelectItem[];
  clinica;
  menuType;
  fotoPerfil;
  email;
  showAddPhotoOverlay = false;
  input = null;
  blobService: any;
  imgChange = new FormControl(false);

  @Input() photo = null;

  form = this.formBuilder.group({
    img: null,
  });

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  fileName: string;
  loading: boolean = false;
  isMenuOpen: boolean = true;

  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    public route: ActivatedRoute,
    public _auth: AuthenticationService,
    private healthUnitService: HealthUnitService,
    private formBuilder: FormBuilder,
    private companyService: CompanyService
  ) {
    {
      this.cities1 = [
        { label: "Configuração 1", value: null },
        { label: "Configuração 2", value: null },
      ];

      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          localStorage.setItem(
            "titulo",
            this.getTitle(router.routerState, router.routerState.root)
              .join("-")
              .replace(",", "")
          );
          var title = this.getTitle(
            router.routerState,
            router.routerState.root
          ).join("-");
          this.title = title.replace(",", "");
          console.log(this.title);
        }
      });
    }

    if (localStorage.getItem("typeUser") == "0") {
      this.menuType = "0";
    } else {
      this.menuType = "1";

      if (localStorage.getItem("userInfo")) {
        var usuario = JSON.parse(localStorage.getItem("userInfo"));

        this.fotoPerfil = usuario["profile"]["photo"];
        var str = usuario["name"].split(" ");
        this.clinica = str[0];
        this.email = usuario["email"];
      }
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.menuType == 0) {
      await this.getCompany();
    }
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        //ok
        this._auth.logout();
      } else {
        //cancel
      }
    });
  }

  logout() {
    this.openDialog("Deseja realmente sair?");
  }

  openFileInput(fileInput) {
    fileInput.click();
    // this.showAddPhotoOverlay = false;
  }

  async removePhoto() {
    this.loading = true;
    this.photo = null;
    this.showAddPhotoOverlay = true;
    var img = document.getElementById("avatarImg");
    (img as any).src = this.photo;
    // this.photoUpdated.emit(this.photo);

    this.loading = false;
  }

  async getCompany(change?: boolean) {
    await this.companyService.getCompany().subscribe(async (res) => {
      console.log("res", res.logo_url);
      if (res.logo_url != "" && res.logo_url != null) {
        this.photo = res.logo_url;
        var img = document.getElementById("avatarImg");
        (img as any).src = this.photo;
        if (change) {
          window.location.reload();
        }
      }
      var str = res.name.split(" ");
      this.clinica = str[0];
      this.loading = false;
    });
  }

  changeImage(event) {
    console.log("event", event.target.files[0]);

    const file: File = event.target.files[0];

    if (file) {
      console.log("file", file);
      this.loading = true;
      this.changeCompanyImage(file);
    }
  }
  async changeCompanyImage(file: File) {
    this.loading = true;
    const params = {
      logo: file,
    };
    await this.companyService.changeCompanyImg(file).subscribe(
      async (res) => {
        this.getCompany(true);
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}

import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { DialogComponent } from "../dialog/dialog.component";
import { Router } from "@angular/router";

export interface DialogData {
  gradeAllData: [];
  enableCancel: boolean;
  diasGrade: [];
  mesSelecionado: string;
  mesTexto: number;
  linha: string;
  position: string;
  uniqueDay: boolean;
  selectDay: number;
  occupation_area_id: number
}

@Component({
  selector: "medlog-schedule-insert",
  templateUrl: "./schedule-insert.component.html",
  styleUrls: ["./schedule-insert.component.scss"],
})
export class ScheduleInsertComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ScheduleInsertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private healthUnitService: HealthUnitService,
    private healtProfessionalService: HealthProfessionalsService,
    private healthProfessionalService: HealthProfessionalsService,
    public dialog: MatDialog,
    private render: Renderer2
  ) {}
  healthUnitsProfissionais = [];
  selectedProf;
  healthProfessionals;
  selectedProfessional;
  loading = true;
  pt: any;
  dateTime;
  modelDataInicio = 0;
  modelDataFim = 0;
  uniqueDay = false;
  editModel: any;

  //escala
  gradeData;
  gradeAllData;
  isEdit = false;
  valorFaturar;
  valorHora;
  selectedGrade;
  periodoTodo = false;
  selecaoManual = false;
  calendarioGrade = false;
  realMonthText;
  realMonth;
  datesOfMonth;
  datesProf = [];
  isSelectedVerify = false;
  targetDiv = [];
  onNoClick(): void {
    this.dialogRef.close("0");
  }

  clearTable() {
    this.gradeAllData = "";
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.uniqueDay = this.data.uniqueDay;
    this.datesOfMonth = this.data.diasGrade;

    console.log(this.data.gradeAllData, 'dataaaaaaaa');
    this.gradeAllData = this.data.gradeAllData;

    if(!this.data.occupation_area_id){
    this.getProfissionais();

    }else{
this.getProfissionaisCategory(this.data.occupation_area_id);
    }
console.log(this.data.selectDay);
console.log(this.formatDia(this.data.selectDay));
    this.datesProf.push({
      isActive: true,
      dia: this.formatDia(this.data.selectDay),
      mes: this.data.mesSelecionado,
      profissional: this.selectedProf,
    });
  }

  getProfissionais() {

    const params = {occupation_area_id: 'aa'}
    this.healthProfessionalService
      .getHealthProfessionals()
      .subscribe((data) => {
        this.loading = false;
        this.healthUnitsProfissionais = data["results"];

        this.healthUnitsProfissionais.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
        this.healthUnitsProfissionais.unshift({
          name: "Selecione o Profissional",
          id: 0,
        });
      });
  }

  getProf() {
    this.isSelectedVerify = false;
  }

  selectedDate(event: any, dia, posicao) {
    if (event.currentTarget.classList.contains("selected")) {
      this.render.removeClass(event.target, "selected");
    } else {
      this.render.addClass(event.target, "selected");
    }

    var s = this.getDiaAtivo(posicao);

    const found = this.datesProf.findIndex(
      (element) => element.dia == this.formatDia(dia)
    );

    if (found >= 0) {
      const filter = this.datesProf.filter(
        (element) => element.dia != this.formatDia(dia)
      );
      this.datesProf = filter;
    } else {
      this.datesProf.push({
        isActive: s,
        dia: this.formatDia(dia),
        mes: this.data.mesSelecionado,
        profissional: this.selectedProf,
      });
    }
  }

  criarEscala() {
    console.log(this.datesProf);
    this.datesProf.map((day)=>{
      day.profissional = this.selectedProf;
    })
   //this.datesProf.push({ profissional: this.selectedProf });
    //const filter = this.datesProf.filter(element => element.isActive == true);
    this.dialogRef.close(this.datesProf);
  }

  ngOnDestroy() {
    //this.editModel = this.data.description;
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home/escala"]);
    });
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home/escala"]);
    });
  }

  selectOpcaoGrade(tipo) {
    if (this.selectedProf) {
      this.datesProf = [];
      this.isSelectedVerify = false;
      var that = this;
      this.calendarioGrade = true;
      const elm = document.querySelector<HTMLElement>(".cdk-overlay-pane")!;
      elm.style.height = "600px";

      const elmContent = document.querySelector<HTMLElement>(
        ".mat-dialog-content"
      )!;
      elmContent.style.height = "400px";

      if (tipo == "periodo") {
        this.loading = true;
        var aPromises = [];
        this.periodoTodo = true;
        this.selecaoManual = false;

        for (var i = 0; i < this.datesOfMonth.length; i++) {
          for (var d = 0; d < this.datesOfMonth[i].dates.length; d++) {
            var positioni = i;
            var positiond = d;
            aPromises.push(
              new Promise(
                function (resolveMaterial, reject) {
                  var s = this.getDiaAtivo(positiond);
                  if (this.datesOfMonth[positioni].dates[positiond] > 0) {
                    this.datesProf.push({
                      isActive: s,
                      dia: this.formatDia(
                        this.datesOfMonth[positioni].dates[positiond]
                      ),
                      mes: this.data.mesSelecionado,
                      profissional: this.selectedProf,
                    });
                  }
                  resolveMaterial();
                }.bind(this)
              )
            );
          }
        }

        Promise.all(aPromises).then(
          function () {
            setTimeout(function () {
              that.loading = false;
              let targetDiv = document.getElementsByClassName("startSelect");
              if (targetDiv != null) {
                for (var d = 0; d < targetDiv.length; d++) {
                  that.render.addClass(targetDiv[d], "selected");
                }
              }
            }, 1000);
          }.bind(this)
        );
      } else {
        this.periodoTodo = false;
        this.selecaoManual = true;
        this.loading = true;
        setTimeout(function () {
          that.loading = false;
          let targetDiv = document.getElementsByClassName("startSelect");
          if (targetDiv != null) {
            for (var d = 0; d < targetDiv.length; d++) {
              that.render.removeClass(targetDiv[d], "selected");
            }
          }
        }, 1000);
      }
    } else {
      this.isSelectedVerify = true;
    }
  }

  getDia(dia) {
    if (dia == "0") {
      return "Domingo";
    } else if (dia == "1") {
      return "Segunda";
    } else if (dia == "2") {
      return "Terça";
    } else if (dia == "3") {
      return "Quarta";
    } else if (dia == "4") {
      return "Quinta";
    } else if (dia == "5") {
      return "Sexta";
    } else {
      return "Sabado";
    }
  }
  getDiaAtivo(dia) {
    if (dia == "0") {
      return this.gradeAllData.days_of_week.sunday;
    } else if (dia == "1") {
      return this.gradeAllData.days_of_week.monday;
    } else if (dia == "2") {
      return this.gradeAllData.days_of_week.tuesday;
    } else if (dia == "3") {
      return this.gradeAllData.days_of_week.wednesday;
    } else if (dia == "4") {
      return this.gradeAllData.days_of_week.thursday;
    } else if (dia == "5") {
      return this.gradeAllData.days_of_week.friday;
    } else {
      return this.gradeAllData.days_of_week.saturday;
    }
  }

  formatDia(dia) {
    if (dia == 0) {
      return "";
    } else {
      if (dia < 10) {
        return "0" + dia.toString();
      } else {
        return dia;
      }
    }
  }

  formatMonth(mes) {
    if (mes == 0) {
      this.realMonth = "01";
    } else if (mes == 1) {
      this.realMonth = "02";
    } else if (mes == 2) {
      this.realMonth = "03";
    } else if (mes == 3) {
      this.realMonth = "04";
    } else if (mes == 4) {
      this.realMonth = "05";
    } else if (mes == 5) {
      this.realMonth = "06";
    } else if (mes == 6) {
      this.realMonth = "07";
    } else if (mes == 7) {
      this.realMonth = "08";
    } else if (mes == 8) {
      this.realMonth = "09";
    } else if (mes == 9) {
      this.realMonth = "10";
    } else if (mes == 10) {
      this.realMonth = "11";
    } else {
      this.realMonth = "12";
    }
  }

  formatMonthText(mes) {
    if (mes == 0) {
      this.realMonthText = "Janeiro";
    } else if (mes == 1) {
      this.realMonthText = "Fevereiro";
    } else if (mes == 2) {
      this.realMonthText = "Março";
    } else if (mes == 3) {
      this.realMonthText = "Abril";
    } else if (mes == 4) {
      this.realMonthText = "Maio";
    } else if (mes == 5) {
      this.realMonthText = "Junho";
    } else if (mes == 6) {
      this.realMonthText = "Julho";
    } else if (mes == 7) {
      this.realMonthText = "Agosto";
    } else if (mes == 8) {
      this.realMonthText = "Setembro";
    } else if (mes == 9) {
      this.realMonthText = "Outubro";
    } else if (mes == 10) {
      this.realMonthText = "Novembro";
    } else {
      this.realMonthText = "Dezembro";
    }
  }

  async getProfissionaisCategory(occupation_area_id){
    console.log(this.gradeAllData, ' gradeAllData');
    await this.healthProfessionalService
    .getHealthProfessionalsByCategory(occupation_area_id)
    .subscribe((data) => {
      this.loading = false;
      this.healthUnitsProfissionais = data["results"];
      console.log(data);
      console.log(this.healthUnitsProfissionais, "aqui");

      this.healthUnitsProfissionais.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnitsProfissionais.unshift({
        name: "Profissionais",
        id: 0,
      });
    });

  console.log(this.healthUnitsProfissionais);
  }
}

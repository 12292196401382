import { sub, subHours } from "date-fns";

export function cleanProperties(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

// Função para extrair hh:mm de uma string de data
export function getHoursMinuts(dataString: string): string {
  // Criar um objeto Date a partir da string
  const data = subHours(new Date(dataString), 3);

  // Obter horas e minutos
  const horas = String(data.getUTCHours()).padStart(2, "0");
  const minutos = String(data.getUTCMinutes()).padStart(2, "0");

  // Retornar no formato desejado (hh:mm)
  return `${horas}:${minutos}`;
}

export function dateFormat(dataString?: string): string {
  if (dataString) {
    // Criar um objeto Date a partir da string
    const data = subHours(new Date(dataString), 3);

    // Obter dia, mês, ano, horas, minutos e segundos
    const dia = String(data.getUTCDate()).padStart(2, "0");
    const mes = String(data.getUTCMonth() + 1).padStart(2, "0"); // Mês começa do zero
    const ano = String(data.getUTCFullYear());
    const horas = String(data.getUTCHours()).padStart(2, "0");
    const minutos = String(data.getUTCMinutes()).padStart(2, "0");
    const segundos = String(data.getUTCSeconds()).padStart(2, "0");

    // Montar a string formatada
    const dataFormatada = `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;

    return dataFormatada;
  } else {
    return "";
  }
}
export function validatorEmail(email) {
  // Expressão regular para validar o email
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ScheduleHistoryService {
  constructor(private http: HttpClient) {}

  public postScheduleHistory(body) {
    return this.http.post<any>(environment.apiUrl + "schedule-history/", body);
  }

  public getWorkSlot(body) {
    return this.http.get<any>(environment.apiUrl + "schedule-history/", body);
  }
}

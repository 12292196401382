import { profileModel } from './profile.model';

export class cadModel { 
    username: string;
    email: string;
    password1: string;
    password2: string;
    name: string;
    profile: profileModel;

}
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../_models/user";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  newLogin(body) {
    return this.http.post<User>(environment.apiUrl + "auth/login/", body);
  }

  login(username: string, password: string, saveLogin: boolean) {
    localStorage.removeItem("company");
    const req = {
      username: username,
      email: username,
      password: password,
    };
    return this.http
      .post<any>(`${environment.apiUrl}auth/login/`, {
        username: username,
        email: username,
        password: password,
      })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.key) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            var dataAtual = new Date();
            var expirar = new Date(dataAtual);
            expirar.setDate(dataAtual.getDate() + 1);

            if (saveLogin) {
              localStorage.setItem("expirarSessao", expirar.toString());
              localStorage.setItem("currentUser", JSON.stringify(user));
            } else {
              sessionStorage.setItem("expirarSessao", expirar.toString());
              sessionStorage.setItem("currentUser", JSON.stringify(user));
            }

            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem("currentUser");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("typeUser");
    localStorage.removeItem("company");
    localStorage.removeItem("userInfo");
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }

  public register(body) {
    return this.http.post<any>(environment.apiUrl + "auth/registration/", body);
  }
}

import { Component, OnInit } from '@angular/core';
import { IUnityReport } from 'src/app/models/unity-report.model';
import * as L from 'leaflet';
import { HealthUnitService } from 'src/app/_services/health-unit.service';
import { IHealthUnit } from 'src/app/_models/health-unit.model';
import interact from 'interactjs';
import { IProfessional } from 'src/app/_models/professional.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { Router } from '@angular/router';
import { HealthProfessionalsService } from 'src/app/_services/health-professionals.service';


@Component({
  selector: 'medlog-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {

  healthUnits;
  professionalUnits;
  results: any[];
  selectedUnit;
  //markers: L.Layer[] = [];
  options;
  constructor(private healthUnitService: HealthUnitService, public dialog: MatDialog, private router: Router, private healthProfessionalsService:HealthProfessionalsService) {
    document.getElementById("menu").click();
   }
  cols: any[];
  colsProf: any[];
  unities: IUnityReport[] = [];
  profs: IProfessional[] = [];
  map;
  points = L.layerGroup();
  showTable = false;
  showTableProf = false;
  loading = true;
  title;
  markers;
  unidades;
  profisa;
  radius = 50;

  latitude = -25.404834;
  longitude = -49.215396;
  latitudes;
  longitudes;
  mapType = 'hybrid';
  zoom = 11;
  iszoom = false;

  ngOnInit() {

    this.title = localStorage.getItem("titulo");

    const params = {
      health_unit_status: 'enable'
    }

    this.healthUnitService.getHealthUnits(params).subscribe(
      data => {

        this.healthUnits = [];


        for (var i = 0; i < data["count"]; i++) {

          if (data["results"][i]["enabled"]) {
            this.healthUnits.push(data["results"][i])
          }


          if (i == data["count"] - 1) {



            this.healthUnits.sort(function (a, b) {
              var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
              if (nameA < nameB) //sort string ascending
                return -1;
              if (nameA > nameB)
                return 1;
              return 0; //default return value (no sorting)
            });
            this.healthUnits.unshift({ name: 'Todas as Unidades', id: 0 });



            this.generateClinicsData();


          }

        }

        (document.querySelector('.insidecontentMap') as HTMLElement).style.height = '430px';


        this.loading = false;

      }
    );

  }

  onResizing(event) {
    (document.querySelector('.insidecontentMap') as HTMLElement).style.height = event.size.height + 'px';
  }

  onResizeStop(event) {

    (document.querySelector('.insidecontentMap') as HTMLElement).style.height = event.size.height + 'px';

  }

  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }



  unityRowSelected(unit) {
    this.selectedUnit = unit
    console.log(unit)
    this.loading = true;
    this.colsProf = [];
    this.profs = [];
    this.showTable = false;
    this.showTableProf = true;


    this.colsProf = [
      { field: 'health_professional_name', header: 'Nome' },
      { field: 'health_professional_email', header: 'E-mail' }

    ];

    this.healthProfessionalsService.getProfessionalUnits(unit.lat, unit.long, unit.radius).subscribe(
      data => {
        console.log(data)

        for (const unidade of data) {

          if (unidade.health_unit_name === unit.name) {
            this.loading = false;
console.log('1')
            
this.markers.push();

            if (unidade.health_professionals?.length > 0) {
console.log('2')
              this.markers = [];
              this.unidades = false;
              this.profisa = true;
              this.zoom = 18;
              this.professionalUnits = [];
              this.professionalUnits = unidade.health_professionals;
              this.latitude = Number(unit.lat);
              this.longitude = Number(unit.long);


              for (const prof of unit.health_professionals) {

                var dataJson = prof.datetime;
                var photoImg = '<img src="' + prof.health_professional_photo + '" height="42px" width="42px"/>';
                var formatado = new Date(dataJson).toLocaleTimeString()
                var nome = prof.health_professional_name.split(" ");

                let proff = {
                  photo: prof.health_professional_photo,
                  email: "",
                  name: nome,
                  user_type: "",
                  expertise: prof.health_professional_expertise,
                  ocupation: prof.health_professional_occupation,
                  horaformatado: formatado,
                  lat: prof.latitude,
                  lng: prof.longitude,
                  datetime: new Date(prof.datetime).toLocaleDateString() + " - " + new Date(prof.datetime).toLocaleTimeString(),
                  elapsed_time: this.formatHour(prof.elapsed_time),
                  health_professional_name: prof.health_professional_name,
                  health_professional_email: prof.health_professional_email,
                  image: prof.health_professional_photo
                }

                this.loading = false;
                this.markers.push(proff);

              }

            } else {
console.log('entroooouuu')
              this.markers = [];


          
              this.zoom = 17;

              this.openDialog(unit);

              this.latitude = Number(unit.lat);
              this.longitude = Number(unit.long);
              this.radius = (parseInt(unit.radius));

              let proff = {
                email: "",
                name: "",
                user_type: "",
                latitude: "",
                longitude: "",
                datetime: "--",
                elapsed_time: "--",
                health_professional_name: "Nenhum Profissional Encontrado",
                health_professional_email: ""
              }



              let selectedRole = this.healthUnits.find(x => x.id == unit.id);
              this.selectedUnit = selectedRole;
            }
      
          }
        }
        this.loading = false;
      }
      
    );
    this.loading = false;
  }




  unitySelected(oEvent) {
    console.log(oEvent);

    this.loading = true;


    if (oEvent.value.id == 0) {

      this.colsProf = [];
      this.profs = [];
      this.unities = [];
      this.showTable = true;
      this.showTableProf = false;
      this.markers = [];
      this.zoom = 11;
      this.iszoom = false;

      this.latitude = -25.404834;
      this.longitude = -49.215396;

      this.generateClinicsData();

    } else {

      this.colsProf = [];
      this.profs = [];
      this.showTable = false;
      this.showTableProf = true;

      this.unidades = false;
      this.profisa = true;
      this.markers = [];



      this.colsProf = [
        { field: 'health_professional_name', header: 'Nome' },
        { field: 'health_professional_email', header: 'E-mail' }
      ];

      this.healthProfessionalsService.getProfessionalUnits(oEvent.value.latitude, oEvent.value.longitude, oEvent.value.radius).subscribe(
        data => {

          console.log(data)
          for (const unit of data) {
            console.log('entrou', unit,oEvent)
            if (unit.health_unit_name === oEvent.value.name) {
              console.log('entrou', unit,oEvent)
              this.radius = (50);
              this.loading = false;
              var count = unit.health_professionals.length;

              if (count > 0) {
                this.professionalUnits = unit.health_professionals

                this.latitude = Number(unit.latitude);
                this.longitude = Number(unit.longitude);
                this.zoom = 17;
                this.radius = parseInt(oEvent.value.radius);

                for (let i = 0; i < count; i++) {

                  var dataJson = this.professionalUnits[i].datetime;
                  var nome = this.professionalUnits[i].health_professional_name.split(" ");
                  var formatado = new Date(dataJson).toLocaleTimeString()
                  let proff = {
                    photo: this.professionalUnits[i].health_professional_photo,
                    expertise: this.professionalUnits[i].health_professional_expertise,
                    ocupation: this.professionalUnits[i].health_professional_occupation,
                    email: "",
                    name: nome,
                    user_type: "",
                    horaformatado: formatado,
                    lat: this.professionalUnits[i].latitude,
                    lng: this.professionalUnits[i].longitude,
                    datetime: new Date(this.professionalUnits[i].datetime).toLocaleDateString() + " - " + new Date(this.professionalUnits[i].datetime).toLocaleTimeString(),
                    elapsed_time: this.formatHour(this.professionalUnits[i].elapsed_time),
                    health_professional_name: this.professionalUnits[i].health_professional_name,
                    health_professional_email: this.professionalUnits[i].health_professional_email,
                    image: this.professionalUnits[i].health_professional_photo
                  }


                  var photoImg = '<img src="' + this.professionalUnits[i].health_professional_photo + '" height="42px" width="42px"/>';
                  this.markers.push(proff);



                }
                this.loading = false;

              } else {

                this.openDialog(oEvent);

                //oEvent.value.latitude, oEvent.value.longitude

                this.latitude = Number(oEvent.value.latitude);
                this.longitude = Number(oEvent.value.longitude);
                this.radius = (parseInt(oEvent.value.radius));

                this.zoom = 17;


                let proff = {
                  email: "",
                  name: "",
                  user_type: "",
                  latitude: "",
                  longitude: "",
                  datetime: "--",
                  elapsed_time: "--",
                  health_professional_name: "Nenhum Profissional Encontrado",
                  health_professional_email: ""
                }

                this.profs.push(proff);
                this.loading = false;
              }
            }
          }
        }
      );


    }
    this.loading = false;
  }

  openDialog(oEvent): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '570px',
      height: '170px',
      panelClass: 'vermelhoPanel',
      data: { description: "Não foram encontrados registros de profissionais nessa unidade." }
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.resetUnities();
      //this.zoom = 11;
      //this.generateClinicsData();
    });
  }
  resetUnities() {
    this.unities = [];
    this.points.eachLayer(function (layer) {
      layer.remove();
    })

    this.cols = [
      { field: 'id', header: '#' },
      { field: 'name', header: 'Nome' }

    ];

    for (let i = 0; i <= this.healthUnits.count; i++) {

      if (this.healthUnits[i].id != 0) {

        var leafIcon = L.icon({
          iconUrl: '/assets/img/preto.png',
          iconSize: [18, 18],
          className: "icon-" + i + "-iconMap"
        });

        let unity = { id: this.healthUnits[i].id, name: this.healthUnits[i].name, lat: this.healthUnits[i].latitude, long: this.healthUnits[i].longitude, radius: this.healthUnits[i].radius }
        this.unities.push(unity);

        var newpopup = L.popup({
          closeOnClick: false,
          autoClose: false
        }).setContent(this.healthUnits[i].name);

        this.points.addLayer(L.marker([parseFloat(this.healthUnits[i].latitude) || -25.450405, parseFloat(this.healthUnits[i].longitude) || -49.267543], { icon: leafIcon }
        ).addTo(this.map
        ).bindPopup(newpopup
        ).openPopup().on('click', function (e) {
          e.target.openPopup();

        }));

        this.map.panTo([this.healthUnits[i].latitude || -25.450405, this.healthUnits[i].longitude || -49.267543]);
        this.showTable = true;
        this.showTableProf = false;
      }
    }

    this.loading = false;

  }

  openUnity(valor) {
    console.log(valor)

    this.unityRowSelected(this.unities[valor])

  }

  generateClinicsData() {

    this.loading = true;

    this.cols = [
      { field: 'id', header: '' },
      { field: 'name', header: 'Nome' }
      /*{ field: 'unity_type', header: 'Tipo de Unidade' },
      { field: 'doctors', header: 'Médicos Online' }*/
    ];

    this.markers = [];

    for (let i = 0; i < this.healthUnits.length; i++) {

      if (i != 0) {

        let unity = { id: this.healthUnits[i].id, name: this.healthUnits[i].name, lat: parseFloat(this.healthUnits[i].latitude) || -25.450405, long: parseFloat(this.healthUnits[i].longitude) || -49.267543, radius: this.healthUnits[i].radius }
        this.unities.push(unity);

        var newpopup = L.popup({
          closeOnClick: false,
          autoClose: false
        }).setContent(this.healthUnits[i].name);

        this.latitude = parseFloat(this.healthUnits[0].latitude) || -25.450405;
        this.longitude = parseFloat(this.healthUnits[0].longitude) || -49.267543;
        this.unidades = true;
        this.profisa = false;


        this.markers.push({ lat: parseFloat(this.healthUnits[i].latitude || -25.450405), lng: parseFloat(this.healthUnits[i].longitude) || -49.267543, nome: this.healthUnits[i].name });

        this.showTable = true;
        this.showTableProf = false;
      }
    }

    this.loading = false;




  }


  zoomChange(valor) {
    if (valor > 15) {
      this.iszoom = false;
    } else if (valor > 11 && this.unidades) {
      this.iszoom = false;
    } else {
      console.log(valor)
      this.iszoom = true;
    }
  }
}

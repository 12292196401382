import { Component, OnInit } from "@angular/core";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import * as L from "leaflet";
import { Router } from "@angular/router";
import { DialogComponent } from "../dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { IHealthUnit } from "src/app/_models/health-unit.model";
declare var AmCharts;
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { DashboardService } from "src/app/_services/dashboard.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { ReportsService } from "src/app/_services/reports.service";

am4core.useTheme(am4themes_animated);


@Component({
  selector: "medlog-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashBoardComponent implements OnInit {
  todayString: string = new Date().toDateString();

  constructor(
    private router: Router,
    private healthUnitService: HealthUnitService,
    private dashboardService: DashboardService,
    private healthProfessionalsService: HealthProfessionalsService,
    private reportsService: ReportsService,
    public dialog: MatDialog
  ) { }
  loading = true;
  healthUnits;
  map;
  points = L.layerGroup();
  professionalUnits;
  cols: any[];
  docs: any[];
  colsContract: any[];
  contracts: any[];
  timer: number;
  title;
  active_health_professionals;
  active_health_units;
  active_invites;
  totalOnline;
  totalUnidades;
  unidadesGrafico;
  totalPorUnidade;
  nomeUnidade;
  colsUnidades: any[];
  unidades: any[];
  telaDash = false;
  telaLista = true;
  listadash;
  mesArray;
  anoArray;
  anolaco;
  selectMonth;
  selectYear;
  public unitFilter: IHealthUnit;
  option = {
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
    separator: '.'
  };

  ngOnInit() {
    this.title = localStorage.getItem("titulo");
    this.title =
      this.title[0].toUpperCase() + this.title.slice(1).toLowerCase();
    console.log(this.title);

    var ano = new Date();
    this.anolaco = ano.getFullYear() - 2;
    this.anoArray = [];
    this.anoArray.push({ id: "00", ano: "Selecione o Ano" });

    for (var i = 1; this.anolaco < ano.getFullYear() + 3; i++) {
      this.anoArray.push({ id: this.anolaco, ano: this.anolaco });
      this.anolaco = Number(this.anolaco) + Number(1);
      this.selectYear = ano.getFullYear();
    }

    let selectedRoles = this.anoArray.find(
      (x) => x.id == ano.getFullYear().toString()
    );
    this.selectYear = selectedRoles;

    this.mesArray = [];
    this.mesArray.push({ id: "-1", mes: "Selecione o mês" });
    this.mesArray.push({ id: "1", mes: "Janeiro" });
    this.mesArray.push({ id: "2", mes: "Fevereiro" });
    this.mesArray.push({ id: "3", mes: "Março" });
    this.mesArray.push({ id: "4", mes: "Abril" });
    this.mesArray.push({ id: "5", mes: "Maio" });
    this.mesArray.push({ id: "6", mes: "Junho" });
    this.mesArray.push({ id: "7", mes: "Julho" });
    this.mesArray.push({ id: "8", mes: "Agosto" });
    this.mesArray.push({ id: "9", mes: "Setembro" });
    this.mesArray.push({ id: "10", mes: "Outubro" });
    this.mesArray.push({ id: "11", mes: "Novembro" });
    this.mesArray.push({ id: "12", mes: "Dezembro" });

    let selectedRole = this.mesArray.find(
      (x) => x.id == (ano.getMonth() + 1).toString()
    );
    this.selectMonth = selectedRole;

    this.report("");
    const params = {
      health_unit_status: 'enable'
    }

    this.healthUnitService.getHealthUnits(params).subscribe((data) => {
      this.healthUnits = data;
      this.generateClinicsData();
      this.healthUnits.results.push({ name: "Todas as Unidades", id: 0 });
      this.healthUnits.results.reverse();
      this.loading = false;
    });

    this.dashboardService.getInfoNumber().subscribe((data) => {
      this.active_health_professionals = data["active_health_professionals"].toString().replace(',', '.');
      this.active_health_units = data["active_health_units"];

      if (data["active_invites"] < "10") {
        this.active_invites = "0" + data["active_invites"];
      } else {
        this.active_invites = data["active_invites"];
      }
    });

    this.dashboardService.getInfoNumberCompany().subscribe((data) => {
      this.contracts = [];
      this.listadash = data;
      this.unidadesGrafico = data["health_units"];

      this.unidadesGrafico.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });

      console.log(this.unidadesGrafico);
      const dataProvider = [];
      this.totalOnline = data["health_professionals_online"];
      this.totalUnidades = data["health_units_quantity"];
    });

    // Create chartdiv1
    //this.options = this.makeOptions(this.makeRandomDataProvider());

    // Create chartdiv2
    //this.chart2 = this.AmCharts.makeChart('chartdiv', this.makeOptions(this.makeRandomDataProvider()));

    //this.options = this.makeOptions(this.makeRandomDataProvider());
    // Update chartdiv2
    //this.AmCharts.updateChart(this.chart2, () => {
    //  this.chart2.dataProvider = this.makeRandomDataProvider();
    //});
  }

  sortNumber(a, b) {
    return b.order - a.order;
  }

  report(event) {
    this.loading = true;

    if (!this.selectMonth || !this.selectYear) {
      this.loading = false;
      //this.openDialog("Mês e ano não podem estar vazios.");
    } else {
      this.unitFilter = new IHealthUnit();
      var primeiroDia = "01";
      var ultimoDia = new Date(
        this.selectYear.id,
        this.selectMonth.id,
        0
      ).getDate();
      var mes;

      //health_units_id: []
      //end_datetime: "2020-03-23T11:02:32.000Z"
      //start_datetime: "2020-03-01T11:02:30.000Z"

      if (this.selectMonth.id < 10) {
        mes = "0" + this.selectMonth.id;
      } else {
        mes = this.selectMonth.id;
      }

      this.unitFilter.health_units_id = [];
      this.unitFilter.start_datetime =
        this.selectYear.id + "-" + mes + "-" + primeiroDia + "T00:00:00.000Z";
      this.unitFilter.end_datetime =
        this.selectYear.id + "-" + mes + "-" + ultimoDia + "T00:00:00.000Z";

      this.reportsService.reportUnitsDash(this.unitFilter).subscribe(
        (data) => {


          return new Promise(
            function (resolve, reject) {
              this.loading = false;
              this.reportUnitis = data;
              var aPromises = [];
              var dadosGrafico = [];
              if (this.reportUnitis.length == 0) {
                this.loading = false;
                this.openDialog("Não foram encontrados registros");
                resolve();
              } else {
                this.loading = false;
                data = data.filter((unit) => unit.total_worked_hours != "00:00:00")
                var height = 30 * data.length;
                (
                  document.querySelector(".chartdiv") as HTMLElement
                ).style.height = height > 300 ? height + "px" : "300px";





                for (const reg of data) {
                  console.log(reg, "reg")
                  dadosGrafico.push({
                    category: reg.health_unit_name,
                    value: Number(reg.total_worked_hours.split(":")[0]),
                    full_name: reg.health_unit_name,
                    target: reg.total_worked_hours,
                  });
                }

                dadosGrafico.sort(function (a, b) {
                  if (a.value > b.value) {
                    return 1;
                  }
                  if (a.value < b.value) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });
                console.log(dadosGrafico)

                if (dadosGrafico.length) {



                  // dadosGrafico.sort(this.sortNumber);


                  console.log(dadosGrafico)


                  var chart = am4core.create("chartdiv", am4charts.XYChart);
                  chart.paddingLeft = 10;
                  chart.data = dadosGrafico;

                  /* Create axes */
                  var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                  categoryAxis.dataFields.category = "category";
                  categoryAxis.renderer.minGridDistance = 25;

                  categoryAxis.cursorTooltipEnabled = false;

                  categoryAxis.renderer.grid.template.disabled = true;
                  // categoryAxis.tooltipText = "{full_name}"
                  // categoryAxis.tooltipPosition = "pointer"
                  categoryAxis.renderer.grid.template.location = 0;

                  var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                  valueAxis.renderer.minGridDistance = 30;

                  valueAxis.renderer.grid.template.disabled = true;
                  valueAxis.min = 0;
                  valueAxis.max = Number(dadosGrafico[dadosGrafico.length - 1].value) * 1.2;
                  valueAxis.strictMinMax = false;

                  valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                    return text;
                  });
                  // valueAxis.height = 50;

                  /* Create ranges */


                  //  this.createRange(valueAxis, 0, 20, am4core.color("#19d228"));
                  //  this.createRange(valueAxis, 20, 40, am4core.color("#b4dd1e"));
                  //  this.createRange(valueAxis, 40, 60, am4core.color("#f4fb16"));
                  //  this.createRange(valueAxis, 60, 80, am4core.color("#f6d32b"));
                  //  this.createRange(valueAxis, 80, 100, am4core.color("#fb7116"));


                  //  category: data[i].health_unit_name,
                  //  value: splitDados[0],
                  //  target: data[i].total_worked_hours,
                  //  order: splitDados[0] + splitDados[1],


                  /* Create series */
                  var series = chart.series.push(new am4charts.ColumnSeries());
                  
                  series.dataFields.valueX = "value";
                  series.dataFields.categoryY = "category";
                  series.columns.template.fill = am4core.color("#00b9de");
                  series.columns.template.stroke = am4core.color("#fff");
                  series.columns.template.strokeWidth = 3;
                  series.columns.template.strokeOpacity = 0.5;
                  series.columns.template.height = am4core.percent(70);
               

                  series.tooltipText = "{target}"

                  // var series2 = chart.series.push(new am4charts.StepLineSeries());
                  // series2.dataFields.valueX = "value";
                  // series2.dataFields.categoryY = "category";
                  // series2.strokeWidth = 3;
                  // series2.noRisers = true;
                  // series2.startLocation = 0.15;
                  // series2.endLocation = 0.85;

                  // series2.stroke = am4core.color("#000");

                  chart.cursor = new am4charts.XYCursor()
                  chart.cursor.lineX.disabled = true;
                  chart.cursor.lineY.disabled = true;

                  valueAxis.cursorTooltipEnabled = false;
                  // chart.height= 500;


                  // {
                  //   type: "serial",
                  //   categoryField: "categoria",
                  //   innerRadius: "20",
                  //   rotate: true,
                  //   autoMarginOffset: 0,
                  //   gridAboveGraphs: true,
                  //   startDuration: 0,
                  //   marginRight: 4,
                  //   marginLeft: 4,
                  //   columnSpacing: 5,
                  //   colors: ["#00b9de"],
                  //   grid: {
                  //     disabled: true,
                  //   },

                  //   categoryAxis: {
                  //     gridPosition: "start",
                  //     gridAlpha: 0,
                  //   },
                  //   graphs: [
                  //     {
                  //       type: "column",
                  //       fillAlphas: 1,
                  //       title: "categoria",
                  //       valueField: "valor",
                  //       labelText: "[[hora]]",
                  //       balloonText: "[[hora]]",
                  //     },
                  //   ],
                  //   theme: "light",
                  //   legend: false,
                  //   dataProvider: dadosGrafico,
                  // }
                }
              }


              this.showTable = true;
              return true;
            }.bind(this)
          );
        },
        (error) => {
          this.loading = false;
          this.openDialog("Ocorreu um erro, tente novamente!");
          return error;
        }
      );
    }
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  unityDash(event) {
    if (event.value.id == 0) {
      this.telaDash = false;
      this.telaLista = true;

      var chart = AmCharts.makeChart("chartdivs", {
        type: "pie",
        innerRadius: 40,
        marginTop: 0,
        startLocation: 0,
        marginRight: 0,
        marginLEft: 0,
        dataProvider: [],
        valueField: "size",
        titleField: "sector",
        pullOutRadius: 10,
        labelRadius: 0,
        balloon: {
          fixedPosition: true,
        },
      });
    } else {
      this.telaDash = true;
      this.telaLista = false;

      var chart = AmCharts.makeChart("chartdivs", {
        type: "pie",
        innerRadius: 40,
        marginTop: 0,
        startLocation: 0,
        marginRight: 0,
        marginLeft: 0,
        dataProvider: [],
        valueField: "size",
        titleField: "sector",
        pullOutRadius: 10,
        labelRadius: 0,
        balloon: {
          fixedPosition: true,
        },
      });

      var dataSelected = this.unidadesGrafico.filter(function (hero) {
        return hero.id == event.value.id;
      });

      var health_professionals = dataSelected[0]["health_professionals"];
      this.totalPorUnidade = dataSelected[0]["health_professionals_online"];
      this.nomeUnidade = dataSelected[0]["name"].length > 25 ? dataSelected[0]["name"].substring(0, 24) : dataSelected[0]["name"];

      const dataProvider = [];
      this.unidades = [];
      //health_professionals[i]["health_professionals_online"]
      for (var i = 0; i < health_professionals.length; i++) {
        let d = new Date(); // creates a Date Object using the clients current time

        let [hours, minutes, seconds] =
          health_professionals[i]["elapsed_time"].split(":");

        dataProvider.push({
          sector: health_professionals[i]["name"],
          size: d.getTime(),
        });
        this.unidades.push({
          name: health_professionals[i]["name"],
          entrada: new Date(
            health_professionals[i]["datetime"]
          ).toLocaleString(),
          tempo: health_professionals[i]["elapsed_time"],
        });
        if (i == health_professionals.length - 1) {
          var chart = AmCharts.makeChart("chartdivs", {
            type: "pie",
            innerRadius: 40,
            marginTop: 0,
            startLocation: 0,
            marginRight: 0,
            marginLEft: 0,
            dataProvider: dataProvider,
            valueField: "size",
            titleField: "sector",
            pullOutRadius: 10,
            labelRadius: -10,
            showBalloon: false,
            balloon: {
              fixedPosition: false,
            },
          });
        }
      }
    }
  }

  generateClinicsData() {
    this.loading = true;

    this.loading = false;
  }

  unitySelected(oEvent) {
    this.loading = true;
    this.points.eachLayer(function (layer) {
      layer.remove();
    });

    if (oEvent.value.id == 0) {
      this.map.setZoom(12);

      for (let i = 0; i <= this.healthUnits.count; i++) {
        //MARKERS MAP
        if (this.healthUnits.results[i].id != 0) {
          var leafIcon = L.icon({
            iconUrl: "/assets/img/preto.png",
            iconSize: [18, 18],
            iconAnchor: [18, 90],
            popupAnchor: [3, -76],
            className: "icon-" + i + "-iconMap",
          });

          var newpopup = L.popup({
            closeOnClick: false,
            autoClose: false,
          }).setContent(this.healthUnits.results[i].name);

          this.points.addLayer(
            L.marker(
              [
                this.healthUnits.results[i].latitude,
                this.healthUnits.results[i].longitude,
              ],
              { icon: leafIcon }
            )
              .addTo(this.map)
              .bindPopup(newpopup)
              .openPopup()
              .on("click", function (e) {
                e.target.openPopup();
              })
          );

          this.map.panTo([
            this.healthUnits.results[i].latitude,
            this.healthUnits.results[i].longitude,
          ]);
          this.loading = false;
        }
      }
    } else {
      var leafIcon = L.icon({
        iconUrl: "/assets/img/preto.png",
        iconSize: [18, 18],
        className: "icon-" + oEvent.value.id + "-iconMap",
      });

      var newpopup = L.popup({
        closeOnClick: false,
        autoClose: false,
      }).setContent(oEvent.value.name);

      this.points.addLayer(
        L.marker([oEvent.value.latitude, oEvent.value.longitude], {
          icon: leafIcon,
        })
          .addTo(this.map)
          .bindPopup(newpopup)
          .openPopup()
          .on("click", function (e) {
            e.target.openPopup();
          })
      );

      this.points.addLayer(
        L.circle(
          [oEvent.value.latitude, oEvent.value.longitude],
          oEvent.value.radius
        ).addTo(this.map)
      );
      var photoImg =
        '<img src="/assets/img/userIcon.png" height="42px" width="42px"/>';

      this.healthProfessionalsService
        .getProfessionalUnits(
          oEvent.value.latitude,
          oEvent.value.longitude,
          oEvent.value.radius
        )
        .subscribe((data) => {
          this.loading = false;
          var leafIcon = L.icon({
            iconUrl: "/assets/img/nada.png",
            iconSize: [18, 18],
            // iconAnchor:   [18, 90],
            //popupAnchor:  [3, -76],
            className: "icon-" + oEvent.value.id + "-iconMap",
          });

          var count = data[0].health_professionals.length;

          if (count > 0) {
            this.professionalUnits = data[0].health_professionals;
            for (let i = 0; i < count; i++) {
              var dataJson = this.professionalUnits[i].datetime;

              var dataFormato = new Date(dataJson);
              var formatado =
                dataFormato.getUTCHours() + ":" + dataFormato.getMinutes();

              var newpopup = L.popup({
                closeOnClick: false,
                autoClose: false,
                className: "userMarker",
              }).setContent(
                "<div class=imgPop>" +
                photoImg +
                "</div><div class=txtPopup><div style=background:#57d0e6>" +
                this.professionalUnits[i].health_professional_name +
                "</div><div style=color:#000>" +
                formatado +
                "</div></div>"
              );

              this.points.addLayer(
                L.marker(
                  [
                    this.professionalUnits[i].latitude,
                    this.professionalUnits[i].longitude,
                  ],
                  { icon: leafIcon }
                )
                  .addTo(this.map)
                  .bindPopup(newpopup)
                  .openPopup()
                  .on("click", function (e) {
                    e.target.openPopup();
                  })
              );
              this.loading = false;
              this.map.setZoom(20);
              this.map.panTo([oEvent.value.latitude, oEvent.value.longitude]);
            }
          } else {
            alert("Nenhum registro encontrado");
          }
        });
    }
  }

  navTo(rota) {
    this.router.navigate([rota]);
  }
  createRange(axis, from, to, color) {
    var range = axis.axisRanges.create();
    range.value = from;
    range.endValue = to;
    range.axisFill.fill = color;
    range.axisFill.fillOpacity = 0.8;
    range.label.disabled = true;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WorkShiftService {
  constructor(private http: HttpClient) {}

  public disableWorkShift(body, id) {
    return this.http.patch<any>(
      environment.apiUrl + "disable-work-shift/" + id + "/",
      body
    );
  }
}

import { Component, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
  description: string;
  enableCancel: boolean;
  type?: string;
  title?: string;
}

export interface DataHistory {
  dia: Dia;
  history: History[];
}
export interface Dia {
  dia: string;
}

export interface History {
  id: string;
  start_date: string;
  finalization_date: string;
  created_at: string;
  updated_at: string;
  created_ate: string;
  updated_ate: string;
  health_professional: string;
  replaced: string;
  period: string;
  planned_workshift: string;
  update_by: string;
  updated_by_name: string;
}

@Component({
  selector: "medlog-dialog",
  templateUrl: "./dialog.component.html",
})
export class DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close("0");
  }

  onCancelClick(): void {
    this.dialogRef.close("1");
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { first } from 'rxjs/operators';
import { DialogComponent } from '../home/dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { HealthUnitService } from '../_services/health-unit.service';
import { UserInfoService } from '../_services/user-info.service';
import { ScheduleHistoryService } from '../_services/schedule-history.service';
import { PlannedWorkshiftService } from '../_services/planned-workshift.service';
import { HealthTeamService } from '../_services/health-team.service';


@Component({
  selector: 'medlog-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('ref', {static: false}) ref;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private healthUnitService : HealthUnitService,
      private userInfoService: UserInfoService,
      private scheduleHistoryService: ScheduleHistoryService,
      private plannedWorkshiftService: PlannedWorkshiftService,
      private healthTeamService: HealthTeamService,

      public dialog: MatDialog
  ) { }

  onClick(event) {
    event.preventDefault();
    this.ref._checked = !this.ref._checked;
    console.log( this.ref._checked, ' this.ref._checked')
    
}

  ngOnInit() {
      
    // this.ref._checked = false;
    
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
      });

      // reset login status
      this.authenticationService.logout();

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }


  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '570px',
      height:'170px',
      panelClass: 'vermelhoPanel',
      data: {description: texto}
    });

    dialogRef.afterClosed().subscribe(result => {
      
      
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    // localStorage.removeItem('company');
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      //var dados = {"email": this.f.username.value, "username": this.f.username.value, "password": this.f.password.value};
      //this.authenticationService.newLogin(dados)
      //  .subscribe(
      //  data => {

      //  })

      this.authenticationService.login(this.f.username.value, this.f.password.value, this.ref._checked)
          .pipe(first())
          .subscribe(
              data => {
                this.loading = true;
                const params = {
                  health_unit_status: 'enable'
                }
                this.healthUnitService.getHealthUnits(params).subscribe(
                  data => {
                    
                    this.router.navigate(['/home/dashboard']);
                    localStorage.setItem("typeUser", "0");
                  },
                  err => {

                    this.userInfoService.getInfo()
                    .subscribe(
                    data => {
                      localStorage.setItem("userInfo", JSON.stringify(data));
                      localStorage.setItem("typeUser", "1");
                      this.router.navigate(['/home/checkin']);
                    } 
                  );



                    
                  } 
                );


              },
              error => {
                  this.openDialog("Usuário ou senha inválido.");
                  this.error = error;
                  this.loading = false;
              });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IClinicReportModel } from 'src/app/models/clinic-report.model';
import { HealthUnitService } from 'src/app/_services/health-unit.service';
import { IHealthUnit } from 'src/app/_models/health-unit.model';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { IHealthUnitProfesional } from 'src/app/_models/health-unit-professional.model';
import { ConsolidatedShiftService } from 'src/app/_services/consolidated-shift.service';
import { ReportsService } from 'src/app/_services/reports.service';

const ELEMENT_DATA: IClinicReportModel[] = [];

@Component({
  selector: 'medlog-clinic-report-user',
  templateUrl: './clinic-report-user.component.html',
  styleUrls: ['./clinic-report-user.component.scss']

})
export class ClinicReportUserComponent implements OnInit {

  constructor(private healthUnitService : HealthUnitService, public dialog: MatDialog, private consolidatedShiftService: ConsolidatedShiftService, private reportsService:ReportsService) { }

  myControl = new FormControl();
  results: any[];
  text: string;
  final:any;
  initial:any
  showTable:boolean = false;
  cols:any[];
  totalHours;
  healthUnits;
  reportUnitis;
  selectedUnit;
  datainicial;
  dataFinal;
  datainicialFormatada;
  datafinalFormatada;
  loading = true;
  modelDataInicio = 0;
  modelDataFim = 0;
  minDateValue;
  filteredOptions: Observable<string[]>;
  matAutoComplete:string;
  public unitFilter: IHealthUnitProfesional;
  public tempoDecorrido;
  clinics:IClinicReportModel[] = []; 
  pt: any;
  title;

  ngOnInit() {
    
    var datas = JSON.parse(localStorage.getItem("userInfo"));
    this.title = "Olá, " +datas["name"];

    
    this.consolidatedShiftService.getLastRegisterConsolidedShift().subscribe(
      data => {
        console.log(data)
        this.healthUnits = data  
          this.healthUnits.results.sort(function(a, b){
            var nameA=a.health_unit_name.toLowerCase(), nameB=b.health_unit_name.toLowerCase();
            if (nameA < nameB) //sort string ascending
             return -1;
            if (nameA > nameB)
             return 1;
            return 0; //default return value (no sorting)
           });
            this.healthUnits.results.unshift({health_unit_name:'Selecione a Unidade', id:-1},) 
        this.loading = false;  
        this.loading = false;
      } 
    );

    var initialDate = new Date();    
    this.initial = initialDate;
    this.totalHours = 0;
    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Segunda Feira", "Terça Feira", "Quarta Feira", "Quinta Feira", "Sexta Feira", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D","S","T","Q","Q","S","S"],
      monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
      monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'mm/dd/yy'
  };

  }

  addDateCalendar(){
   this.minDateValue = new Date(this.modelDataInicio);
   this.minDateValue.setDate(this.minDateValue.getDate() + 30);   

  }
  formatHour(data){
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] +":"+dataHora[1] + ":"+ segundos[0];
  }
  formatDate(data: Date){
    var newMonth;
    var newDay;;
    var novaData = new Date(data);
    var year = novaData.getFullYear();
    var month = novaData.getMonth() + 1;
    var day = novaData.getDay();
    var hour = novaData.getHours();
    var minute = novaData.getMinutes();
    
    if (month < 10 ){
      newMonth = "0" + month;
    }else{
      newMonth = month;
    }
    
    if (day < 10 ){
      newDay = "0" + day;
    }else{
      newDay = day;
    }

    return year + "-" + newMonth + "-" + newDay +"T"+hour+":"+minute+":00Z";
    
  }

  formatDatePT(data: Date){

    return data.toLocaleDateString();
  }


   timestrToSec(timestr) {
    var parts = timestr.split(":");
    return (parts[0] * 3600) +
           (parts[1] * 60) +
           (+parts[2]);
  }
  
   pad(num) {
    if(num < 10) {
      return "0" + num;
    } else {
      return "" + num;
    }
  }
  
formatTime(seconds) {
    return [this.pad(Math.floor(seconds/3600)),
            this.pad(Math.floor(seconds/60)%60),
            this.pad(seconds%60),
            ].join(":");
  }

reportClinic(){
    this.clinics = [];
    this.loading = true;
    var times = 0;
    
    if ((this.modelDataInicio != 0) && (this.modelDataFim != 0) && (this.selectedUnit.health_unit != -1)){

      this.datainicial = new Date(this.modelDataInicio + new Date().getTimezoneOffset() * 60000).toISOString();
      this.dataFinal = new Date(this.modelDataFim + new Date().getTimezoneOffset() * 60000).toISOString();
      this.unitFilter = new IHealthUnitProfesional();
      
      if (this.selectedUnit.health_unit == -1){
        this.unitFilter.health_unit_id = "";
      }else{
        this.unitFilter.health_unit_id = this.selectedUnit.health_unit;
      }
      this.unitFilter.end_datetime = this.dataFinal;
      this.unitFilter.start_datetime = this.datainicial;

      this.cols = [
        { field: 'prof', header: 'Profissional' },
        { field: 'clinic', header: 'Unidade' },
        { field: 'date', header: 'Período' },
        { field: 'hours', header: 'Tempo' }   
      ];
      
      this.reportsService.reportProfessional(this.unitFilter)
        .subscribe(
        data => {
          
          return new Promise(function (resolve, reject) {

            this.loading = false;
            this.reportUnitis = data["consolidated_shifts"];
            var aPromises = [];
            
            if(this.reportUnitis.length == 0){
              this.openDialog("Não foram encontrados registros");
              resolve();
            }else{
              this.totalHours = data["total_worked_hours"];

              for (let i = 0; i < this.reportUnitis.length; i++) {

                aPromises.push(new Promise(function (resolves, reject) {
                 let cli =   {
                    prof: this.reportUnitis[i].health_professional_name,
                    clinic: this.reportUnitis[i].health_unit_name,
                    date: new Date(this.reportUnitis[i].start_datetime).toLocaleString() + " - " + new Date(this.reportUnitis[i].end_datetime).toLocaleString() , 
                    hours: this.reportUnitis[i].worked_hours
                  };
                  this.clinics.push(cli);
                  resolves();
					      }.bind(this)));
              }

              Promise.all(aPromises).then(function () {
                resolve();
              }.bind(this));
      
            }

            this.showTable = true;
            return true;

          }.bind(this));
         
        },
        error => {
          this.loading = false;
          this.openDialog("Ocorreu um erro, tente novamente!");
          return error;
        }
        );
    }else{
      this.loading = false;
      this.openDialog("Necessário preencher todos.");
    }
  }


  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '570px',
      height:'170px',
      panelClass: 'vermelhoPanel',
      data: {description: texto}
    });

    dialogRef.afterClosed().subscribe(result => {
      
      
    });
  }

  search(event) {
    this.reportClinic();
    //this.results = this.healthUnits.results
}

 
  



}

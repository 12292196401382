export class IHealthUnit{    
        health_unit_id: any[];
        health_units_id: any[];
        start_datetime: string;
        end_datetime: string;
        adjusted_end_datetime: string;
        adjusted_start_datetime: string;
        id: string;
        name: string;
        health_professional_id: string;
        health_professionals_id: any[];
        health_professional: string;
        health_unit: string;
        disable_adjustment: string;
        disable_work_shift: string;
        image: string;
        doctor: string; 
        clinic: string;
        modelDataInicio: number;
        modelDataFim: number;
        occupation_area?:string;
        company_id:string;
        period?: string;
        grid_id?: string;
  area_of_expertise: any;


        
}
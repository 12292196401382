import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IClinicReportModel } from 'src/app/models/clinic-report.model';
import { HealthUnitService } from 'src/app/_services/health-unit.service';
import { IHealthUnit } from 'src/app/_models/health-unit.model';
import { formatDate } from '@angular/common';
import { UnitsModel } from 'src/app/_models/units.model';
import { Router } from '@angular/router';
import { iGridUnit } from 'src/app/_models/gridmodel.model';
import { iUnit } from 'src/app/_models/unit.model';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { cleanProperties } from 'src/utils';
import { HttpClient } from '@angular/common/http';



const ELEMENT_DATA: IClinicReportModel[] = [
];

@Component({
  selector: 'medlog-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']

})
export class UnitsComponent implements OnInit {
  https: any;

  constructor(public dialog: MatDialog, private router: Router, private healthUnitService: HealthUnitService, private http: HttpClient) {
    document.getElementById("menu").click();
  }

  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  optional: any;
  tipo: any;
  initial: any
  showTable: boolean = false;
  cols: any[];
  healthUnits;
  loading = true;

  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  public unitFilter: IHealthUnit;
  healthType;
  healthTypeId;

  clinics: iGridUnit[] = [];
  pt: any;
  title;
  searchText: string = '';
  previous: string;

  result = [];
  searchKeyword;
  sort = 0;
  lastProperties = {
    limit: 10,
    offset: 0,
  }
  lenght = 0;

  @HostListener('input') onkeyup(event) {
    if (event?.which === 13) {
      this.requestData(this.lastProperties)
    }
  }

  //   @HostListener('input') oninput() {
  //     this.searchItems();
  //   }


  //   private checkAgainstProperty(property: any): boolean {
  //     let value: boolean = false;


  //     if (property.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0) {
  //       value = true;

  //     }

  //     return value;
  // }

  //   searchItems() {


  //     this.result = this.clinics.filter(o => this.checkAgainstProperty(o.name));


  //     if (this.result.length === 0) {
  //     return [-1];
  //     }
  //     return this.result;


  //   }


  async ngOnInit() {

    this.title = localStorage.getItem("titulo");

    this.cols = [
      { field: 'id', header: '#' },
      { field: 'nome', header: 'Nome' },
      { field: 'tipo', header: 'Tipo' },
      { field: 'cidade', header: 'Cidade' },
      { field: 'email', header: 'Telefone' },
      { field: 'registro', header: 'Registro' },
      { field: 'action', header: 'Opções' }
    ];


    await this.requestData(this.lastProperties);;

  }
  requestData(event) {
   // if(event?.rows)this.lastProperties.limit = event?.rows;
   this.lastProperties.offset = event?.first;

    const params = {
      limit:  this.lastProperties.limit,
      offset:  this.lastProperties.offset,
      search: this.searchText === '' ? null : this.searchText
    }

    this.healthUnitService.getHealthUnits(cleanProperties(params)).subscribe(
      data => {
        this.lenght = data.count;
        this.loading = false;
        const units = [];

        for (const unit of data.results) {
          let unity = {
            address: unit.address,
            address_complement: unit.address_complement,
            address_number: unit.address_number,
            cel_number: unit.cel_number,
            city: unit.city,
            cnpj: unit.cnpj,
            company: unit.company,
            enabled: unit.enabled,
            id: unit.id,
            latitude: unit.latitude,
            longitude: unit.longitude,
            name: unit.name,
            phone_number: unit.phone_number,
            radius: unit.radius,
            responsible: unit.responsible,
            state: unit.state,
            type: unit.type,
            created_at: new Date(unit.created_at).toLocaleDateString(),
            disabled_at: unit?.disabled_at ? new Date(unit.disabled_at).toLocaleDateString() : null

          }
          units.push(unity);



        }
        this.result = units;
      }
    );
  }

  onSort() {
    if (this.sort == 0) {

      this.result.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 1;

    } else {

      this.result.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameB < nameA) //sort string ascending
          return -1;
        if (nameB > nameA)
          return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 0;

    }


  }
  onSortTipo() {

    if (this.sort == 0) {

      this.result.sort(function (a, b) {
        var nameA = a.type.toLowerCase(), nameB = b.type.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 1;

    } else {

      this.result.sort(function (a, b) {
        var nameA = a.type.toLowerCase(), nameB = b.type.toLowerCase();
        if (nameB < nameA) //sort string ascending
          return -1;
        if (nameB > nameA)
          return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 0;

    }


  }
  onSortCidade() {

    if (this.sort == 0) {

      this.result.sort(function (a, b) {
        var nameA = a.city.toLowerCase(), nameB = b.city.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 1;

    } else {

      this.result.sort(function (a, b) {
        var nameA = a.city.toLowerCase(), nameB = b.city.toLowerCase();
        if (nameB < nameA) //sort string ascending
          return -1;
        if (nameB > nameA)
          return 1;
        return 0; //default return value (no sorting)
      });

      this.sort = 0;

    }


  }

  getUnit() {
    this.loading = true;
    this.clinics = [];
    this.healthUnitService.getHealthUnits().subscribe(
      data => {
        this.healthUnits = data;
        this.loading = false;


        for (let i = 0; i <= this.healthUnits.count; i++) {
          let unity =
          {
            address: this.healthUnits.results[i]?.address,
            address_complement: this.healthUnits.results[i]?.address_complement,
            address_number: this.healthUnits.results[i]?.address_number,
            cel_number: this.healthUnits.results[i]?.cel_number,
            city: this.healthUnits.results[i]?.city,
            cnpj: this.healthUnits.results[i]?.cnpj,
            company: this.healthUnits.results[i].company,
            enabled: this.healthUnits.results[i].enabled,
            id: this.healthUnits.results[i].id,
            latitude: this.healthUnits.results[i].latitude,
            longitude: this.healthUnits.results[i].longitude,
            name: this.healthUnits.results[i].name,
            phone_number: this.healthUnits.results[i].phone_number,
            radius: this.healthUnits.results[i].radius,
            responsible: this.healthUnits.results[i].responsible,
            state: this.healthUnits.results[i].state,
            type: this.healthUnits.results[i].type,
            created_at: this.formatDatePT(this.healthUnits.results[i].created_at),
            disabled_at: this.healthUnits.results[i]?.disabled_at ? new Date(this.healthUnits.results[i].disabled_at).toLocaleDateString() : null


          }
          this.clinics.push(unity);
          this.result = this.clinics;


        }
      }
    );

  }

  disableClinic(valor) {
    // this.openDialog("Deseja inativar unidade?", true, this.result[event], false);
    const apiUrl = 'https://staging-medlog-api.herokuapp.com/v1/health-units-management/';
    const url = `${apiUrl}${valor}/disable/`;
  
    this.http.patch(url, null).subscribe(
      (_response) => {
        this.requestData(this.lastProperties)
        this.openDialog((_response as any).message, valor, false);
      },
      (_error) => {
        // this.openDialog('Erro ao desativar unidade', valor, false);
        //error
      }
    );
  }

  enableClinic(valor) {
    // this.openDialog("Deseja ativar unidade?", this.result[event], true);
    const apiUrl = 'https://staging-medlog-api.herokuapp.com/v1/health-units-management/';
    const url = `${apiUrl}${valor}/enable/`;
    this.http.patch(url, null).subscribe(
      (_response) => {
        this.requestData(this.lastProperties)
        this.openDialog((_response as any).message, valor, false);
        
      },
      (_error) => {
        // this.open((_response as any).message, valor, false);
        // error
      }
    );
  }

  saveUnit(i, tipo) {

    this.loading = true;
    var unitForm = new iUnit();


    this.healthUnitService.getType().subscribe(
      data => {
        this.loading = false;
        this.healthType = data;

        this.healthTypeId = "a4cc04af-ab02-497f-87c3-e8de430d0a3b";
        for (var c = 0; c < this.healthType.count; c++) {
          if (this.healthType.results[c].name == i.type) {
            this.healthTypeId = this.healthType.results[c].id
          } else {
            this.healthTypeId = "a4cc04af-ab02-497f-87c3-e8de430d0a3b";
          }
        }

        unitForm.name = i.name;
        unitForm.type = this.healthTypeId;
        unitForm.cnpj = i.cnpj;
        unitForm.responsible = i.responsible;
        unitForm.phone_number = i.phone_number;
        unitForm.cel_number = i.cel_number;
        unitForm.address = i.address;
        unitForm.address_number = i.address_number;
        unitForm.address_complement = i.address_complement;
        unitForm.city = i.city;
        unitForm.state = i.state;
        unitForm.latitude = parseFloat(i.latitude.toString()).toFixed(6);
        unitForm.longitude = parseFloat(i.longitude.toString()).toFixed(6);
        unitForm.radius = i.radius;
        unitForm.enabled = tipo;

        this.healthUnitService.editUnit(unitForm, i.id).subscribe(
          data => {

            this.openDialog("Edição concluído com sucesso!!", false, "");
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.openDialog("Ocorreu um erro, tente novamente!", false, "");
            return error;
          }
        );



      },
      error => {
        this.loading = false;
        this.openDialog("Ocorreu um erro, tente novamente!", false, "");
        return error;
      }
    );
  }

  openDialog(texto, valor, enable): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '570px',
      height: '170px',
      panelClass: "verdePanel",
      data: { description: texto, enableCancel: enable }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == "0") {
        //ok
        if (enable) {
          this.saveUnit(this.optional, this.tipo);
        }

        if (this.optional == "refresh") {
          this.getUnit();
        }

      } else {
        //cancel
      }

    });
  }

  editarUnidade(valor) {

    this.router.navigate(['/home/editunit', valor]);
  }

  addUnit() {
    this.router.navigate(['/home/addunidades']);

  }

  formatDatePT(data: Date) {
    //"2019-04-01T22:23:52Z"
    // 2019-06-07T22:23:42.136442Z
    var novaData = new Date(data);
    var year = novaData.getFullYear();
    var month = novaData.getMonth() + 1;
    var day = novaData.getDay();
    var newMonth;
    var newDay;

    if (month < 10) {
      newMonth = "0" + month;
    }

    if (day < 10) {
      newDay = "0" + day;
    }
    return newDay + "/" + newMonth + "/" + year;
  }

}
